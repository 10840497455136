import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN,USER_EMAIL,USER_NAME,USER_AVATAR, USER_TYPE, USER_CONTACT, USER_ID, 
		COMPANY_IMG_REGULAR, COMPANY_IMG_REGULAR_SM, COMPANY_IMG_WHITE, COMPANY_IMG_WHITE_SM } from 'constants/AuthConstant';

import AuthService from 'services/AuthService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem('auth_token') || null,
	type: localStorage.getItem('user_type') || null,
}


export const signIn = createAsyncThunk('/Login',async (data, { rejectWithValue }) => {
	
	const { email, password } = data
	try {
		const response = await AuthService.login({email, password})
		console.log(response);

		if (!response.status) {
			return rejectWithValue(response.message?.replace('Error: ', ''));
		}
		
		if (response.status) {	
			// initialState.type = response.data.type
			// initialState.token = response.data.Xtoken
			const token = response.data.Xtoken;
			localStorage.setItem(AUTH_TOKEN, token);

			const userTpye = response.data.type;
			localStorage.setItem(USER_TYPE, userTpye)

			const userEmail = response.data.email;
			localStorage.setItem(USER_EMAIL, userEmail);

			const userName = response.data.username;
			localStorage.setItem(USER_NAME, userName);

			const userAvatar = response.data.avatar;
			localStorage.setItem(USER_AVATAR, userAvatar);
			
			const userContact = response.data.contact_number;
			localStorage.setItem(USER_CONTACT, userContact);

			const userID = response.data.ID;
			localStorage.setItem(USER_ID, userID);

			console.log("authSlice");
			return token;
			
		}
	
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
// 	const { email, password } = data
// 	try {
// 		const response = await AuthService.register({email, password})
// 		const token = response.data.token;
// 		localStorage.setItem(AUTH_TOKEN, token);
// 		return token;
// 	} catch (err) {
// 		return rejectWithValue(err.response?.data?.message || 'Error')
// 	}
// })

export const signOut = createAsyncThunk('auth/logout',async () => {
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(USER_EMAIL);
	localStorage.removeItem(USER_NAME);
	localStorage.removeItem(USER_AVATAR);
	localStorage.removeItem(USER_TYPE);	
	localStorage.removeItem(USER_CONTACT);	
	localStorage.removeItem(USER_ID);	

	localStorage.removeItem(COMPANY_IMG_REGULAR);	
	localStorage.removeItem(COMPANY_IMG_REGULAR_SM);	
	localStorage.removeItem(COMPANY_IMG_WHITE);	
	localStorage.removeItem(COMPANY_IMG_WHITE_SM);	

})

// export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
//     try {
// 		const response = await AuthService.loginInOAuth()
// 		const token = response.data.token;
// 		localStorage.setItem(AUTH_TOKEN, token);
// 		return token;
// 	} catch (err) {
// 		return rejectWithValue(err.response?.data?.message || 'Error')
// 	}
// })

// export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
//     try {
// 		const response = await AuthService.loginInOAuth()
// 		const token = response.data.token;
// 		localStorage.setItem(AUTH_TOKEN, token);
// 		return token;
// 	} catch (err) {
// 		return rejectWithValue(err.response?.data?.message || 'Error')
// 	}
// })


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			// .addCase(signUp.pending, (state) => {
			// 	state.loading = true
			// })
			// .addCase(signUp.fulfilled, (state, action) => {
			// 	state.loading = false
			// 	state.redirect = '/'
			// 	state.token = action.payload
			// })
			// .addCase(signUp.rejected, (state, action) => {
			// 	state.message = action.payload
			// 	state.showMessage = true
			// 	state.loading = false
			// })
			// .addCase(signInWithGoogle.pending, (state) => {
			// 	state.loading = true
			// })
			// .addCase(signInWithGoogle.fulfilled, (state, action) => {
			// 	state.loading = false
			// 	state.redirect = '/'
			// 	state.token = action.payload
			// })
			// .addCase(signInWithGoogle.rejected, (state, action) => {
			// 	state.message = action.payload
			// 	state.showMessage = true
			// 	state.loading = false
			// })
			// .addCase(signInWithFacebook.pending, (state) => {
			// 	state.loading = true
			// })
			// .addCase(signInWithFacebook.fulfilled, (state, action) => {
			// 	state.loading = false
			// 	state.redirect = '/'
			// 	state.token = action.payload
			// })
			// .addCase(signInWithFacebook.rejected, (state, action) => {
			// 	state.message = action.payload
			// 	state.showMessage = true
			// 	state.loading = false
			// })
	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer