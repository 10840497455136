import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import { API_BASE_URL } from 'constants/ApiConstant';
import { ASSETS_PROFILE } from 'constants/PathsConstant';
import { USER_ID } from 'constants/AuthConstant';


const Message = () => {
  const [message, setMessage] = useState([]);
  const img = "elon.jpg";
  // console.log("USER_ID", localStorage.getItem(USER_ID))
  // console.log("USER_ID_asdlkj", `my-channel-${localStorage.getItem(USER_ID)}`)

  useEffect(() => {
    // Enable pusher logging - don't include this in production
    // Pusher.logToConsole = true;

    const pusher = new Pusher('2a68bf37f29c53f1f6b5', {
      cluster: 'ap2',
      forceTLS: true
    });
    const channel = pusher.subscribe(`my-channel-${localStorage.getItem(USER_ID)}`);
    channel.bind('my-event', data => {
      // setMessage([...message, data.data]);
      console.log({data});
 
      showNotification(data)
    });
  }, [message]);
  const showNotification = (message) => {
    console.log({message});

    const notification = new Notification(message.message,
      {
      icon: `${API_BASE_URL+ASSETS_PROFILE+img}`,
      body: "Hey there! You've been notified!",

      // body: JSON.stringify(message.message),
    });

    notification.onclick = function() {
      // Handle click action if needed
      console.log(message);
    };
  };
  return (
    <>
    </>
  );
};

export default Message;
