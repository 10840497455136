import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'


export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    
]

export const protectedRoutes = 
[
    //profile
    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('components/layout-components/Profile/ProfilePage')),
    },
    {
        key: 'profile.change_password',
        path: `${APP_PREFIX_PATH}/change_password`,
        component: React.lazy(() => import('components/layout-components/Profile/ChangePassword')),
    },
    // nav notifications (View All)
    {
        key: 'dashboard.notifications',
        path: `${APP_PREFIX_PATH}/notifications`,
        component: React.lazy(() => import('components/shared-components/Notification/NotificationTable')),
    },
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.analytic',
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        component: React.lazy(() => import('views/app-views/dashboards/analytic')),
    },
    {
        key: 'dashboard.task-taskList',
        path: `${APP_PREFIX_PATH}/dashboards/task/tasklist`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/TaskList')),
    },
    {
        key: 'dashboard.task-taskAdd',
        path: `${APP_PREFIX_PATH}/dashboards/task/taskadd`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/AddNewTaskFrom')),
    },
    {
        key: 'dashboard.task-taskOnBoard', 
        path: `${APP_PREFIX_PATH}/dashboards/task/taskOnBoard/edit_task/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/EditTask')),
    },
    {
        key: 'dashboard.task-taskOnBoard', 
        path: `${APP_PREFIX_PATH}/dashboards/task/view_task/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/view/ViewTaskOnBoard')),
    },
    {
        key: 'dashboard.task-taskOnBoard',
        path: `${APP_PREFIX_PATH}/dashboards/task/TaskOnBoard`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard')),
    },
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboards/task/unpaidtask`,
        component: React.lazy(() => import('views/app-views/dashboards/default/otherTasks/unpaidtask/UnPaidTask')),
    },
    {
        key: 'dashboard', 
        path: `${APP_PREFIX_PATH}/task/unpaidtask/view_task/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/default/otherTasks/unpaidtask/ViewUnPaiDTask')),
    },
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/payment/taskpayment/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/default/otherTasks/unpaidtask/TaskPayment')),
    },
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboards/task/unassignedtask`,
        component: React.lazy(() => import('views/app-views/dashboards/default/otherTasks/UnAssignedTask')),
    },
    //expense route
    {
        key: 'dashboard.task-taskOnBoard',
        path: `${APP_PREFIX_PATH}/dashboards/task/taskOnBoard/view_task/editexpence/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/view/edit')),
    },
    {
        key: 'dashboard.task-taskOnBoard',
        path: `${APP_PREFIX_PATH}/dashboards/task/taskOnBoard/addexpence`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/view/add')),
    },
    
    {
        key: 'dashboard.task-taskOnBoard',
        path: `${APP_PREFIX_PATH}/dashboards/task/taskOnBoard/addsubtask`,
            component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/view/SubTask/AddSubTask')),
        // component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/view/SubTask/EditSubTask')),
    },
    {
        key: 'dashboard.task-taskOnBoard',
        path: `${APP_PREFIX_PATH}/dashboards/task/taskOnBoard/editsubtask/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/view/SubTask/EditSubTask')),
    },
    {
        key: 'dashboard',
        // path: `${APP_PREFIX_PATH}/client/client_list/view/view_client_invoice/:id`,
        path: `${APP_PREFIX_PATH}/task/invoice_raised/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/RaiseInvoice')),
    },

    {
        key: 'dashboard.task-reports',
        path: `${APP_PREFIX_PATH}/dashboards/task/taskreports`,
        component: React.lazy(() => import('views/app-views/dashboards/Task/taskreports')),
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales')),
    },
    {
        key: 'dashboard.employees',
        path: `${APP_PREFIX_PATH}/dashboards/employees`,
        component: React.lazy(() => import('views/app-views/dashboards/employees/index')),
    },
    {
        key: 'dashboard.employees',
        path: `${APP_PREFIX_PATH}/dashboards/employees/add_employee`,
        component: React.lazy(() => import('views/app-views/dashboards/employees/AddEmployeeForm')),
    },
    {
        key: 'dashboard.employees',
        path: `${APP_PREFIX_PATH}/dashboards/employees/edit_employee/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/employees/ActionButtons/EditEmployeeForm')),
    },
    {
        key: 'dashboard.employees',
        path: `${APP_PREFIX_PATH}/dashboards/employees/reset_password/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/employees/ActionButtons/ResetPassword')),
    },
    {
        key: 'dashboard.employees',
        path: `${APP_PREFIX_PATH}/dashboards/employees/permission/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/employees/ActionButtons/Permission')),
    },
    {
        key: 'dashboard.employees',
        path: `${APP_PREFIX_PATH}/dashboards/employees/view/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/employees/ActionButtons/ViewEmployee')),
    },
    {
        key: 'dashboard.appointment',
        path: `${APP_PREFIX_PATH}/dashboards/appointment`,
        component: React.lazy(() => import('views/app-views/dashboards/Appointment/index')),
    },
    {
        key: 'dashboard.fileManager',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager')),
    },
    {
        key: 'dashboard.fileManager',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager/view_dispatch_file`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager/ViewDispatch/ViewDispatchFile')),
    },
    {
        key: 'dashboard.fileManager',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager/add_file_location`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager/manageloactionTable/ManageLocation')),
    },
    {
        key: 'dashboard.fileManager',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager/edit_location/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager/manageloactionTable/EditLocation')),
    },
    {
        key: 'dashboard.fileManager',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager/add_location`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager/manageloactionTable/AddLocation')),
    },
    {
        key: 'dashboard.addfile',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager/addfile`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager/form/AddFile')),
    },
    {
        key: 'dashboard.fileManager',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager/update_dispatech_file/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager/form/DispatchForm')),
    },
    {
        key: 'dashboard.fileManager',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager/edit_file_manage/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager/form/EditFile')),
    },
    {
        key: 'dashboard.fileManager',
        path: `${APP_PREFIX_PATH}/dashboards/file_manager/add_file_location/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/file-manager/form/EditFile')),
    },
    // ========================== Apps ===========================//
    {
        key: 'apps',
        path: `${APP_PREFIX_PATH}/apps`,
        component: React.lazy(() => import('views/app-views/apps')),
    },
    {
        key: 'apps.mail',
        path: `${APP_PREFIX_PATH}/apps/mail/*`,
        component: React.lazy(() => import('views/app-views/apps/mail')),
    },
    {
        key: 'apps.chat',
        path: `${APP_PREFIX_PATH}/apps/chat/*`,
        component: React.lazy(() => import('views/app-views/apps/chat')),
    },
    // {
    //     key: 'apps.chat',
    //     path: `${APP_PREFIX_PATH}/apps/chat/*`,
    //     component: React.lazy(() => import('views/app-views/apps/chat')),
    // },
    // {
    //     key: 'apps.chatById',
    //     path: `${APP_PREFIX_PATH}/apps/chat/:id`,
    //     component: React.lazy(() => import('views/app-views/apps/chat')),
    // },
    {
        key: 'apps.calendar',
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        component: React.lazy(() => import('views/app-views/apps/calendar')),
    },
    {
        key: 'apps.project',
        path: `${APP_PREFIX_PATH}/apps/project`,
        component: React.lazy(() => import('views/app-views/apps/project')),
    },
    {
        key: 'apps.project.list',
        path: `${APP_PREFIX_PATH}/apps/project/list`,
        component: React.lazy(() => import('views/app-views/apps/project/project-list/ProjectList')),
    },
    {
        key: 'apps.project.scrumboard',
        path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
        component: React.lazy(() => import('views/app-views/apps/project/scrumboard')),
    },
    {
        key: 'apps.ecommerce',
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce')),
    },
    {
        key: 'apps.ecommerce.add-product',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/add-product`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce/add-product')),
    },
    {
        key: 'apps.ecommerce.edit-product',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/edit-product/:id`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce/edit-product')),
    },
    {
        key: 'apps.ecommerce.product-list',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce/product-list')),
    },
    {
        key: 'apps.ecommerce.orders',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce/orders')),
    },
    // ========================== Payments ===========================//

    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment/receipt`,
        component: React.lazy(() => import('views/app-views/payment/receipt/index')),
    },
    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment/receipt/addreceiptform`,
        component: React.lazy(() => import('views/app-views/payment/receipt/addReceiptForm')),
    },
    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment/receipt/viewreceipt/:id`,
        component: React.lazy(() => import('views/app-views/payment/receipt/actionbuttons/ViewReceipt')),
    },
    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment/invoice_list`,
        component: React.lazy(() => import('views/app-views/payment/invoice-list/index')),
    },
    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment/add_invoice`,
        component: React.lazy(() => import('views/app-views/payment/invoice-list/actionbuttons/AddNewInvoice')),
    },
    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment/edit_invoice/:id`,
        component: React.lazy(() => import('views/app-views/payment/invoice-list/actionbuttons/EditInvoiceList')),
    },
    {
        key: 'payment', 
        path: `${APP_PREFIX_PATH}/payment/custom_invoice`,
        component: React.lazy(() => import('views/app-views/payment/custom-invoice/index')),
    },
    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment/addcustom_invoice`,
        component: React.lazy(() => import('views/app-views/payment/custom-invoice/actionbuttons/addcutomInvoice')),
    },
    {
        key: 'payment',
        path: `${APP_PREFIX_PATH}/payment/editcustom_invoice/:id`,
        component: React.lazy(() => import('views/app-views/payment/custom-invoice/actionbuttons/EditCustomeInvoice')),
    },

    // ==========================leave ===========================//

    {
        key: 'leave',
        path: `${APP_PREFIX_PATH}/leave/adminleave`,
        component: React.lazy(() => import('views/app-views/leave/AdminLeave/index')),
    },
    {
        key: 'leave',
        path: `${APP_PREFIX_PATH}/leave/addadminleave`,
        component: React.lazy(() => import('views/app-views/leave/AdminLeave/actionbuttons/addAdminLeave')),
    },
    {
        key: 'leave',
        path: `${APP_PREFIX_PATH}/leave/edit_adminleave/:id`,
        component: React.lazy(() => import('views/app-views/leave/AdminLeave/actionbuttons/EditAdminLeave')),
    },
    {
        key: 'leave',
        path: `${APP_PREFIX_PATH}/leave/employeeleave`,
        component: React.lazy(() => import('views/app-views/leave/EmployeeLeave/index')),
    },
    {
        key: 'leave',
        path: `${APP_PREFIX_PATH}/leave/manageHoliday`,
        component: React.lazy(() => import('views/app-views/leave/ManageHoliday/index')),
    },
    {
        key: 'leave',
        path: `${APP_PREFIX_PATH}/leave/manage_holiday/add_holiday`,
        component: React.lazy(() => import('views/app-views/leave/ManageHoliday/form/AddHoliday')),
    },
    {
        key: 'leave',
        path: `${APP_PREFIX_PATH}/leave/manage_holiday/edit_holiday/:id`,
        component: React.lazy(() => import('views/app-views/leave/ManageHoliday/form/EditHoliday')),
    },

    // ========================== Reports ===========================//
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/performance`,
        component: React.lazy(() => import('views/app-views/reports/performance/index')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/performance/view_employee_report/:id`,
        component: React.lazy(() => import('views/app-views/reports/performance/EmpPerformanceChart')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/due_report`,
        component: React.lazy(() => import('views/app-views/reports/due/index')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/attendance_log`,
        component: React.lazy(() => import('views/app-views/reports/attendance-log/index')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/attendance_report`,
        component: React.lazy(() => import('views/app-views/reports/attendance-report/index')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/attendance_report/view_attendence_report/:id`,
        component: React.lazy(() => import('views/app-views/reports/attendance-report/AttendanceReport')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/gst_report`,
        component: React.lazy(() => import('views/app-views/reports/gst/index')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/gst_report/show_gst_report/:id`,
        component: React.lazy(() => import('views/app-views/reports/gst/GSTReport')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/attendance_log/add_attendance`,
        component: React.lazy(() => import('views/app-views/reports/attendance-log/actionbuttons/AddAttendanceForm')),
    },
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/attendance_log/edit/:id`,
        component: React.lazy(() => import('views/app-views/reports/attendance-log/actionbuttons/EditAttendance')),
    },

    // ==========================settings ===========================//
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/notification`,
        component: React.lazy(() => import('views/app-views/settings/notification/index')),
    },
    {
        key: 'settings.notification',
        path: `${APP_PREFIX_PATH}/settings/notification/sendsms`,
        component: React.lazy(() => import('views/app-views/settings/notification/forms/SendSMS')),
    },
    {
        key: 'settings.notification',
        path: `${APP_PREFIX_PATH}/settings/notification/sendemail`,
        component: React.lazy(() => import('views/app-views/settings/notification/forms/SendEmail')),
    },
    {
        key: 'settings.notification',
        path: `${APP_PREFIX_PATH}/settings/notification/daily_notification_to_user`,
        component: React.lazy(() => import('views/app-views/settings/notification/DailyNotification')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/payment`,
        component: React.lazy(() => import('views/app-views/settings/payment/index')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/expences`,
        component: React.lazy(() => import('views/app-views/settings/expences/index')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/smsconfig`,
        component: React.lazy(() => import('views/app-views/settings/smsconfig/index')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/smsconfig/edit/:id`,
        component: React.lazy(() => import('views/app-views/settings/smsconfig/EditSMSConfig')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/notifiactionconfig`,
        component: React.lazy(() => import('views/app-views/settings/notificationconfig/NotificationConfig')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/notifiactionconfig/edit/:id`,
        component: React.lazy(() => import('views/app-views/settings/notificationconfig/EditNotiConfig')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/testing`,
        component: React.lazy(() => import('views/app-views/settings/testing/Testing')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/department`,
        component: React.lazy(() => import('views/app-views/settings/department/index')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/vaultM`,
        component: React.lazy(() => import('views/app-views/settings/vaultM/index')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/addvaultmanger`,
        component: React.lazy(() => import('views/app-views/settings/vaultM/AddVaultManager')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/payment/editpaymethod/:id`,
        component: React.lazy(() => import('views/app-views/settings/payment/ActionButtons/EditPayMethod')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/expences/editexpence/:id`,
        component: React.lazy(() => import('views/app-views/settings/expences/ActionButtons/EditExpence')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/department/editdepartment/:id`,
        component: React.lazy(() => import('views/app-views/settings/department/ActionButtons/EditDepartment')),
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings/vaultM/editvault/:id`,
        component: React.lazy(() => import('views/app-views/settings/vaultM/ActionButton/EditVaultManager')),
    },
    //clients
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/list`,
        component: React.lazy(() => import('views/app-views/client/client-list/index')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/company`,
        component: React.lazy(() => import('views/app-views/client/company/index')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/manual_payment`,
        component: React.lazy(() => import('views/app-views/client/clientM_payment/index')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/password`,
        component: React.lazy(() => import('views/app-views/client/client-password/index')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/addnewpassword`,
        component: React.lazy(() => import('views/app-views/client/client-password/addNewPassword')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/client_password/edit_password/:id`,
        component: React.lazy(() => import('views/app-views/client/client-password/ActionButtons/EditClientPassword')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/logs`,
        component: React.lazy(() => import('views/app-views/client/client-activityLog/index')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/addclientform`,
        component: React.lazy(() => import('views/app-views/client/client-list/AddClientForm')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/client_list/edit_client/:id`,
        component: React.lazy(() => import('views/app-views/client/client-list/Actionbutton/EditClientForm')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/client_list/resetpassword/:id`,
        component: React.lazy(() => import('views/app-views/client/client-list/Actionbutton/ClientResetPassword')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/client_list/view/:id`,
        component: React.lazy(() => import('views/app-views/client/client-list/Actionbutton/ClientView')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/client_list/view/edit_client_log/:id`,
        component: React.lazy(() => import('views/app-views/client/client-list/Actionbutton/ClientViewActionButtons/EditClientLog')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/client_list/view/edit_client_invoice/:id`,
        component: React.lazy(() => import('views/app-views/client/client-list/Actionbutton/ClientViewActionButtons/EditClientInvoice')),
    },
    {
        key: 'client',
        // path: `${APP_PREFIX_PATH}/client/client_list/view/view_client_invoice/:id`,
        path: `${APP_PREFIX_PATH}/invoice/generate_invoice/:id`,
        component: React.lazy(() => import('views/app-views/client/client-list/Actionbutton/ClientViewActionButtons/ViewClientInvoice')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/addadmin_company`,
        component: React.lazy(() => import('views/app-views/client/company/GeneralCompanyForm/AddAdminCo')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/company/view/:id`,
        component: React.lazy(() => import('views/app-views/client/company/View/CompanyView')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/company/view/edit_virtual_file/:id`,
        component: React.lazy(() => import('views/app-views/client/company/View/EditVirtualFile')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/company/edit/:id`,
        component: React.lazy(() => import('views/app-views/client/company/GeneralCompanyForm/EditCompany')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/manage_group`,
        component: React.lazy(() => import('views/app-views/client/company/manage-group/ManageGroup')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/Add_company_group`,
        component: React.lazy(() => import('views/app-views/client/company/manage-group/AddGroup')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/edit_company_group/:id`,
        component: React.lazy(() => import('views/app-views/client/company/manage-group/EditGroup')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/view_company_group/:id`,
        component: React.lazy(() => import('views/app-views/client/company/manage-group/ViewGroup')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/manage_comments`,
        component: React.lazy(() => import('views/app-views/client/company/manage-comments/index')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/add_comment`,
        component: React.lazy(() => import('views/app-views/client/company/manage-comments/AddComment')),
    },
    {
        key: 'client',
        path: `${APP_PREFIX_PATH}/client/edit_comment/:id`,
        component: React.lazy(() => import('views/app-views/client/company/manage-comments/EditComment')),
    },
    {
        key: 'docs.documentation',
        path: `${APP_PREFIX_PATH}/docs/documentation/*`,
        component: React.lazy(() => import('views/app-views/docs')),
    },

]
export const empProtectedRoutes = [
        {
            key: 'emp_profile',
            path: `${APP_PREFIX_PATH}/profile`,
            component: React.lazy(() => import('components/layout-components/Profile/ProfilePage')),
        },
        {
            key: 'dashboard.task-taskOnBoard', 
            path: `${APP_PREFIX_PATH}/task/view_task/:id`,
            component: React.lazy(() => import('views/app-views/dashboards/Task/taskonboard/actionbuttons/view/ViewTaskOnBoard')),
        },
        // nav notifications (View All)
        {
            key: 'dashboard.notifications',
            path: `${APP_PREFIX_PATH}/notifications`,
            component: React.lazy(() => import('components/shared-components/Notification/NotificationTable')),
        },
        {
            key: 'empdashboard',
            path: `${APP_PREFIX_PATH}/dashboards/default`,
            component: React.lazy(() => import('views/app-views/other-views/emp-view/dashboard/index')),
        },
        {
            key: 'empdashboard',
            path: `${APP_PREFIX_PATH}/Task/View_sub_task/:id`,
            component: React.lazy(() => import('views/app-views/other-views/emp-view/dashboard/actionBtn/ViewSubTask')),
        },
        {
            key: 'emp-tasklist',
            path: `${APP_PREFIX_PATH}/tasklist`,
            component: React.lazy(() => import('views/app-views/other-views/emp-view/task-list/index')),
        },
        {
            key: 'emp-leave-application',
            path: `${APP_PREFIX_PATH}/leave`,
            component: React.lazy(() => import('views/app-views/other-views/emp-view/leave-application/index')),
        },
        {
            key: 'emp-leave-application-add',
            path: `${APP_PREFIX_PATH}/leave/add`,
            component: React.lazy(() => import('views/app-views/other-views/emp-view/leave-application/form/addLeave')),
        },
        {
            key: 'emp-leave-application-edit',
            path: `${APP_PREFIX_PATH}/leave/edit/:id`,
            component: React.lazy(() => import('views/app-views/other-views/emp-view/leave-application/form/EditLeave')),
        },
        {
            key: 'emp-admin-chat',
            path: `${APP_PREFIX_PATH}/employee/chat/*`,
            component: React.lazy(() => import('views/app-views/other-views/emp-view/admin-chat/index')),
        }
    ];
export const clientProtectedRoutes = [
        // nav notifications (View All)
        {
            key: 'dashboard.notifications',
            path: `${APP_PREFIX_PATH}/notifications`,
            component: React.lazy(() => import('components/shared-components/Notification/NotificationTable')),
        },
        {
            key: 'test',
            path: `${APP_PREFIX_PATH}/test`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/testing/test')),
        },
        {
            key: 'ClientDashboard',
            path: `${APP_PREFIX_PATH}/dashboards/default`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Dashboard/index')),
        },
        {
            key: 'client_company',
            path: `${APP_PREFIX_PATH}/company`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/CompanyDetails/index')),
        },
        // {
        //     key: 'client_manual_payment',
        //     path: `${APP_PREFIX_PATH}/client_manual_payment`,
        //     component: React.lazy(() => import('views/app-views/other-views/client-view/CompanyDetails/index')),
        // }
        {
            key: 'client_manual_payment',
            path: `${APP_PREFIX_PATH}/client/manual_payment`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/manualPayment/index')),
        },
        // tasklist
        {
            key: 'client_ticket',
            path: `${APP_PREFIX_PATH}/ticket`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Ticket/index')),
        },
        {
            key: 'client_add_task',
            path: `${APP_PREFIX_PATH}/ticket/add`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Ticket/AddTask')),
        },
        {
            key: 'client_ticket',
            path: `${APP_PREFIX_PATH}/ticket/view_task/:id`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Ticket/actionBtn/View')),
        },



        // invoice
        {
            key: 'client_invoice',
            path: `${APP_PREFIX_PATH}/invoice`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Invoice/index')),
        },
        {
            key: 'client_invoice',
            // path: `${APP_PREFIX_PATH}/client/client_list/view/view_client_invoice/:id`,
            path: `${APP_PREFIX_PATH}/invoice/generate_invoice/:id`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Invoice/actionBtn/PrintInvoice')),
        },

        {
            key: 'client_appointment',
            path: `${APP_PREFIX_PATH}/appointment`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Appointment/index')),
        },
        
        // all action and other buttons
        //company list
        {
            key: 'paynow',
            path: `${APP_PREFIX_PATH}/pay_pending_amount/:id`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/CompanyDetails/form/PayNow')),
        },
        {
            key: 'client_company_add',
            path: `${APP_PREFIX_PATH}/company/add`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/CompanyDetails/AddCompany')),
        },
        {
            key: 'client_documents',
            path: `${APP_PREFIX_PATH}/ClientCompanyDocument/:id`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/CompanyDetails/actionBtn/DocumentListTable')),
        },
        {
            key: 'client_passbook',
            path: `${APP_PREFIX_PATH}/passbook/:id`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/CompanyDetails/actionBtn/Passbook')),
        },

        //notification
        {
            key: 'notification',
            path: `${APP_PREFIX_PATH}/notifications`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/CompanyDetails/index')),
        },
        
        // appointment
        {
            key: 'client_appointment',
            path: `${APP_PREFIX_PATH}/appointment/add`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Appointment/form/AddAppointment')),
        },
        {
            key: 'client_appointment',
            path: `${APP_PREFIX_PATH}/appointment/edit/:id`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Appointment/form/EditAppointment')),
        },
        {
            key: 'client_profile',
            path: `${APP_PREFIX_PATH}/profile`,
            component: React.lazy(() => import('components/layout-components/Profile/ProfilePage')),
        },
        //dashboard cards routes
        {
            key: 'client_ongoing_jobs_cards',
            path: `${APP_PREFIX_PATH}/client/ongoing_jobs`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Dashboard/cards/Ongoing_jobs')),
        },
        {
            key: 'client_query_raised_cards',
            path: `${APP_PREFIX_PATH}/client/query_raised`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/Dashboard/cards/Query_Raised')),
        },
        // chat
        {
            key: 'apps.chat',
            path: `${APP_PREFIX_PATH}/adminchat/chat/*`,
            component: React.lazy(() => import('views/app-views/other-views/client-view/chat')),
        },
];