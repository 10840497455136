const dev = { API_ENDPOINT_URL: 'https://task.mysyva.net/backend' };

const prod = { API_ENDPOINT_URL: 'https://task.mysyva.net/backend' };

const test = { API_ENDPOINT_URL: 'https://task.mysyva.net/backend' };

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
